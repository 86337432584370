import Vue from "vue";

/*TITLE*/
document.title = "Residencial Los Álamos | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Los Álamos";
Vue.prototype.$subtitle = "Viviendas de lujo en urbanización privada";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Los Álamos";
Vue.prototype.$introSubtitle = "Viviendas de lujo en urbanización privada";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ac4-natural-1l-michigan-clear_modificado--20230127100126.jpg";
Vue.prototype.$image_kitchen = "cocina-opc.-3-pavimento-smart-tanzania-almond-revestimiento-glaciar-blanco-nubol-encimera-krion-snow-white-copete--20230127100151.jpg";
Vue.prototype.$image_bath1 = "banop-op2.image-dark-image-dark--20230127100119.jpg";
Vue.prototype.$image_bath2 = "banosec-op2.-smart-tanzania-nut-glaciar-smart-tanzania-nut--20230127100137.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ac4-natural-1l-michigan-clear_modificado--20230127100126.jpg",
  },
  {
    src: "salon-ac4-baltic-1l-copenhage_modificado--20230127100132.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-opc.-3-pavimento-smart-tanzania-almond-revestimiento-glaciar-blanco-nubol-encimera-krion-snow-white-copete--20230127100151.jpg",
  },
  {
    src: "cocina-opc.-4-pavimento-smart-tanzania-almond-revestimiento-glaciar-graffiti-encimera-krion-snow-white-copete--20230127100158.jpg",
  },
  {
    src: "cocina-opc.-1-pavimento-smart-tanzania-almond-revestimiento-glaciar-bison-encimera-krion-snow-white-copete--20230127100109.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-op2.image-dark-image-dark--20230127100119.jpg",
  },
  {
    src: "banop-op1.-bianco-carrarabianco-carrara--20230127100129.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-op2.-smart-tanzania-nut-glaciar-smart-tanzania-nut--20230127100137.jpg",
  },
  {
    src: "banosec-op1.-smart-vancouver-sand-urban-caliza-nature-smart-vancouver-sand--20230127100144.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LOS_ALAMOS/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/92qNtOfCab8";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=playa%20del%20sardinero%2039002%20santander",
    text: "Playa del Sardinero - 39002 Santander",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20calvo%20sotelo%2012%20bajo%2039002%20santander",
    text: "C/ Calvo Sotelo 12, Bajo -  39002 Santander",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:942214778-942214857-699494510",
    text: "942214778-942214857-699494510",
  },
  {
    icon: "mdi-email",
    link: "mailto:ventas@viviendasrobera.com",
    text: "ventas@viviendasrobera.com",
  },
];
